import _production from "./production";
export { _production as default };
export const __esModule = _production.__esModule,
  createApp = _production.createApp,
  isV1Config = _production.isV1Config,
  MessageType = _production.MessageType,
  LifecycleHook = _production.LifecycleHook,
  PermissionType = _production.PermissionType,
  createAppWrapper = _production.createAppWrapper,
  createClientApp = _production.createClientApp,
  WINDOW_UNDEFINED_MESSAGE = _production.WINDOW_UNDEFINED_MESSAGE;